<template>
	<div id="box">
		<div class="box">
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="奖补申报文件库" name="first">
					<div class="top">
						<div class="topitem">
							<span>级别</span>
							<el-select v-model="selectParms.policyLevel" clearable placeholder="请选择">
								<el-option v-for="item in rankoptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="topitem">
							<span>类型</span>
							<el-select v-model="selectParms.policyType" clearable placeholder="请选择">
								<el-option v-for="item in typeoptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="topitem">
							<span>关键字</span>
							<el-input placeholder="请输入政策相关信息" prefix-icon="el-icon-search"
								v-model="selectParms.kw">
							</el-input>
						</div>
						<div class="topitem">
							<span>日期</span>
							<el-date-picker v-model="selectParms.datevalue" type="date" placeholder="选择日期"
								format="yyyy 年 MM 月 dd 日" value-format="timestamp">
							</el-date-picker>
						</div>
						<div class="topbtn">
							<el-button type="primary" @click="selects">搜索</el-button>
							<el-button @click="reset">重置</el-button>
						</div>
					</div>
					<div class="center">
						<el-table :data="tableData" stripe border :header-cell-style="{
			  					'text-align':'center',
			  					'font-size':'16px',
			  					'color':'#333'
			  				}" style="width: 100%">
							<el-table-column prop="policyId" label="编号" width="80" align="center">
							</el-table-column>
							<el-table-column label="扶持政策" width="450">
								<template slot-scope="scope">
									<el-button @click="handleClick(scope.row)" type="text" size="small">
										{{scope.row.policyName}}</el-button>
								</template>
							</el-table-column>
							<el-table-column prop="policyTypeName" label="政策类型" align="center">
							</el-table-column>
							<el-table-column prop="policyLevelName" label="级别" align="center">
							</el-table-column>
							<el-table-column prop="publishDate" label="日期" align="center">
							</el-table-column>
						</el-table>
						<div class="page">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page="currentPage.page" :page-sizes="[5,10, 20, 30, 40]"
								:page-size="currentPage.limit" layout=" prev, pager, next, jumper,sizes,total"
								:total="currentPage.count">
							</el-pagination>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="政策减负" name="second">
					<div class="top2">
						<div class="tlift"><potlift></potlift></div>
						<div class="tright"><potright></potright></div>
					</div>
					<div class="bom2">
						<div class="blift"><poblift></poblift></div>
						<div class="bright"><pobright></pobright></div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

	</div>
</template>

<script>
	import potlift from "@/components/policy/potlift.vue"
	import potright from "@/components/policy/potright.vue"
	import poblift from "@/components/policy/poblift.vue"
	import pobright from "@/components/policy/pobright.vue"
	import Swiper from "swiper"
	import {readBcode,readBcode2,listPolicy,listPolicy2} from "@/api/policy/policy"
	export default {
		data() {
			return {
				rankoptions: [],
				selectParms: {
					policyLevel: "",
					policyType: '',
					kw: '',
					datevalue: '',
				},
				typeoptions: [],
				swiperlist: null,


				tableData: [],
				currentPage: {
					page: 1,
					limit: 5,
					count: 0
				},
				limit: 5,
				activeName: "second"

			}
		},
		mounted() {
			this.getRankoptions()
			this.gettypeoptions()
			this.getListPolicy()

		},
		methods: {
			handleClick(val) {
				console.log(val)
				this.$alert(val.content, val.policyName, {
					customClass: 'message',
					dangerouslyUseHTMLString: true,
					showConfirmButton: false,
					showCancelButton: false,
					closeOnPressEscape: true,
				}).then(() => {

				}).catch((err) => {
					console.log(err)
				});
			},
			// swpter() {
			// 	var _this = this
			// 	var myswiper = new Swiper('.swiper-container', {
			// 		loop: true,
			// 		autoplay: true,
			// 		grabCursor: true,
			// 		// 一行显示slider的个数
			// 		slidesPerView: 4,
			// 		// 定义slides的数量多少为一组
			// 		slidesPerGroup: 1,
			// 		// 分页
			// 		pagination: {
			// 			el: '.swiper-pagination',
			// 			// 设为true, 点击分页器的指示点分页器会控制Swiper切换
			// 			clickable: true,
			// 		},
			// 		navigation: {
			// 			nextEl: '.swiper-button-next',
			// 			prevEl: '.swiper-button-prev',
			// 		},
			// 		on: {
			// 			click: function(swiper) {
			// 				// console.log(JSON.parse(swiper.target.offsetParent.getAttribute('axx')))
			// 				_this.handleClick(JSON.parse(swiper.target.offsetParent.getAttribute('axx')))
			// 			}
			// 		},
			// 	})
			// 	myswiper.el.onmouseover = function() { //鼠标放上暂停轮播
			// 		myswiper.autoplay.stop();
			// 	}
			// 	myswiper.el.onmouseleave = function() {
			// 		myswiper.autoplay.start();
			// 	}
			// },
			handleSizeChange(val) {
				//	console.log(`每页 ${val} 条`);
				this.currentPage.limit = val
				this.getListPolicy()
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.currentPage.page = val
				this.getListPolicy()
			},
			getRankoptions() {
				let _this = this
				readBcode().then(res => {
					if (res.data.data) {
						let options = res.data.data;
						options.forEach(item => {
							_this.rankoptions.push({
								label: item.codeLabel,
								value: item.codeValue
							})
						})
					}
				})
			},
			gettypeoptions() {
				let _this = this
				readBcode2().then(res => {
					if (res.data.data) {
						let options = res.data.data;
						options.forEach(item => {
							_this.typeoptions.push({
								label: item.codeLabel,
								value: item.codeValue
							})
						})
					}
				})
			},
			getListPolicy() {
				let _this = this
				_this.selectParms.page = _this.currentPage.page
				_this.selectParms.limit = _this.currentPage.limit
				// console.log(_this.selectParms)
				listPolicy(_this.selectParms.page,_this.selectParms.limit).then(res => {
					if (res.data.data) {
						_this.tableData = res.data.data;
						_this.currentPage.count = res.data.count;
					}else{
            _this.tableData=[]
          }
					// if (!_this.swiperlist) {
					// 	_this.swiperlist = _this.tableData
					// 	_this.swiperlist.forEach(item => {
					// 		var re = /<[^<>]+>/g;
					// 		// console.log(res.data.data[0].content.replace(re,'').replace(/&nbsp/g,'').replace(/;/g,'').split("第一条"))
					// 		item.content = item.content.replace(re, '').replace(/&nbsp/g, '').replace(/;/g,
					// 			'')
					// 	})
					// 	setTimeout(() => {
					// 		_this.swpter()
					// 	}, 500)
					// }
				})
			},
			getSwiperData() {
				let _this = this
				// _this.$axios({
				// 	url: `/apg/longGang/policy/listPolicy`,
				// 	method: 'post',
				// 	data: {
				// 		page: 1,
				// 		limit: 100
				// 	}, // body参数
				// 	params: {} // query参数
				// })
				listPolicy2(1,100).then(res => {
					if (res.data.data) {
						_this.tableData1 = res.data.data;
						if (res.data.count / 3 === 0) {
							_this.slidesPerGroup = res.data.count / 3
						} else {
							_this.slidesPerGroup = (res.data.count / 3) + 1
						}

					}
				})
			},
      selects(){
        this.currentPage.page=1
        this.getListPolicy()
      },
      reset(){
        this.selectParms.policyLevel=""
        this.selectParms.kw=""
        this.selectParms.policyType=""
       this.currentPage.page=1
        this.getListPolicy()
      }
		},
		components:{
			potlift,
			potright,
			poblift,
			pobright
		}
	}
</script>

<style scoped="scoped" lang="less">
	.bom {
		width: 100%;
		height: 250px;
		background-color: #FFFFFF;
		padding: 4px;
		margin-top: 20px;
		border-radius: 4px;

		.bomtitle {
			width: 100%;
			height: 50px;
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			color: #FFFFFF;
			background-image: url(../../assets/img/zhengce.png);
			background-size: 100% 100%;
			line-height: 50px;
			background-repeat: no-repeat;
			margin-bottom: 10px;
		}

		.swiper-container {
			width: 100%;
			height: 190px;

			.swiper-wrapper {
				width: 100%;
				height: 100%;

				.swiper-slide {
					height: 100%;
					padding: 10px;

					.switem {
						height: 100%;
						width: 100%;
						border: 1px solid #999999;
						padding: 4px;
						border-radius: 4px;
						position: relative;
						overflow: hidden;

						h1 {
							text-align: center;
							margin: 10px 0 !important;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						p {
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							display: -webkit-box;
							text-overflow: ellipsis;
							overflow: hidden;
							padding: 0 10px;
						}

						span {
							position: absolute;
							right: 14px;
							bottom: 14px;
							color: #999999;
							font-size: 14px;
						}
					}
				}
			}

			.swiper-button-prev {
				z-index: 10;
			}

			.swiper-button-next {
				z-index: 10;
			}
		}
	}

	.box {
		width: 100%;
		min-height: 700px;
		background-color: #f2f3f5;
		border-radius: 4px;

		.top {
			width: 100%;
			display: flex;
			background-color: #FFFFFF;
			padding: 4px;
			height: 150px;
			border-radius: 4px;
			position: relative;

			.topitem {
				display: flex;
				align-items: center;
				height: 100px;

				.el-select {
					width: 200px;
				}

				span {
					white-space: nowrap;
					margin-right: 10px;
					margin-left: 10px;
				}

				.el-input {
					width: 200px;
				}
			}

			.topbtn {
				position: absolute;
				width: 157px;
				right: 4px;
				bottom: 4px;
			}
		}

		.center {
			width: 100%;
			min-height: 200px;
			background-color: #FFFFFF;
			padding: 20px;
			border-radius: 4px;
			margin-top: 20px;

			.page {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: end;
				margin-top: 15px;
			}
		}
		.top2{
			width: 100%;
			height: 280px;
			// background-color: #FFFFFF;
			margin-top: 20px;
			display: flex;
			justify-content: space-around;

			.tlift{
				width: 48%;
				height: 100%;
				background-color: #FFFFFF;
        border-radius: 4px;
			}
			.tright{
				width: 48%;
				height: 100%;
				background-color: #FFFFFF;
        border-radius: 4px;
			}
		}
		.bom2{
			width: 100%;
			height: 280px;
			// background-color: #FFFFFF;
			margin-top: 60px;
			display: flex;
			justify-content: space-around;

			.blift{
				width: 48%;
				height: 100%;
				background-color: #FFFFFF;
        border-radius: 4px;
			}
			.bright{
				width: 48%;
				height: 100%;
				background-color: #FFFFFF;
        border-radius: 4px;
			}
		}
	}
</style>
