<template>
	<div class="boxx">
		<div ref="bar" class="bar"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			var box = this.$refs.bar
			this.chart(box)
		},
		methods: {
			chart(box) {
				var myChart = this.$echarts.init(box);
				var option;
				option = {
					xAxis: {
						type: 'category',
						data: ['2016', '2017', '2018', '2019', '2020'],
					},
					yAxis: [{
							type: 'value',
							name: "单位：万元", 
							position: "lift",
						}, 
						{
							type: 'value',
							name: "单位：家",
							position: "right",
							interval: 15,
						}
					],
					grid: {
						bottom: "20%"
					},
					series: [
						{
							data: [1800, 1500, 1900, 2000, 1300],
							type: 'bar',
							barWidth: 25,
							itemStyle: {
							 color: "#02A6EF"
							}
						},
						{
							data: [60, 45, 40, 60, 40],
							type: 'line',
							yAxisIndex:1,
							symbolSize: 20,
							symbol: "circle",
							itemStyle: {
								normal:{
									color:"#FABD88",
									lineStyle:{
										color:'#A1FF80'
									}
								}
							}
						},
					],
					tooltip: {
					    show: true,
					},
					title: {
						show: 'true',
						text: '政策兑现数、金额',
						textStyle: {
							color: "#333333",
							fontSize: 18,
						}
					}
				};
				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx {
		width: 100%;
		height: 100%;

		.bar {
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>
