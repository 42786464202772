<template>
  <div class="boxx">
    <div class="bar" id="ppp" ref="pie"></div>
  </div>
</template>

<script>
import Highcharts from "highcharts/highstock";
import Highcharts3D from "highcharts/highcharts-3d";
Highcharts3D(Highcharts);
export default {
  data() {
    return {
      data: [
        {
          name: "工业",
          y: 64,
        },
        {
          name: "两直资金",
          y: 1,
        },
        {
          name: "人才",
          y: 42,
        },
        {
          name: "开放型经济",
          y: 29,
        },
        {
          name: "抗疫惠企",
          y: 0,
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.chart(this.data);
  },
  methods: {
    chart(data) {
      var _this = this;
      var chart = Highcharts.chart("ppp", {
        title: {
          text: "",
          align: "left",
          style: {
            // color: "#fff",
            // fontSize:"0.554165rem"
          },
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
          backgroundColor: "rgba(0,0,0,0)",
        },
        tooltip: {
          pointFormat: "{point.y}</b>",
        },
        legend: {
          itemStyle: {
            // color: "#fff",
            // fontSize:"0.554165rem",
          },
          zIndex: 100,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 50,
            size: "90%",
            dataLabels: {
              enabled: true,
              useHTML: true,
              crop: false,
              formatter: function (val) {
                var name = this.point.name.split("");
                var arr = "";
                name.forEach((item, i) => {
                  if (i % 7 == 0 && i > 0) {
                    item += "<br>";
                  }
                  arr += item;
                });
                var str2 = arr;
                return str2 + ":<br>" + this.y + "条";
              },
            },
            showInLegend: true,
          },
        },
        series: [
          {
            type: "pie",
            name: "产业政策兑现",
            // colors:["#18b6da","#ffd20d","#5f7bd4"],
            dataLabels: {
              // color: '#fff',
              style: {
                // fontSize:"0.554165rem"
              },
            },
            data: data,
            events: {
              click: function (event) {
                console.log(event.point.options);
              },
            },
          },
        ],
        title: {
          show: "true",
          text: "产业政策兑现",
          align: "left",
          floating: true,
          x: 0,
          y: 10,
          margin: 15,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "black",
          },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.boxx {
  width: 100%;
  height: 100%;

  .bar {
    width: 100%;
    height: 100%;
    border-radius:5px ;
  }
}
</style>
