<template>
	<div class="boxx">
		<div ref="bar" class="bar"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			var box = this.$refs.bar
			this.chart(box)
		},
		methods: {
			chart(box) {
				var myChart = this.$echarts.init(box);
				var option;
				option = {
					xAxis: {
						type: 'category',
						data: ['收费减免', '减缓工会经费', '租金减免', '税收优惠', '优化贷款'],
						axisLabel: {
							interval: 0,
							// formatter: function(val) {
							//  var strs = val.split(''); //字符串数组
							//  var str = ''
							//  for(var i = 0, s; s = strs[i++];) { //遍历字符串数组
							//  str += s;
							//  if(!(i % 5)) str += '\n'; //按需要求余
							//  }
							//  return str
							// }
						}
					},
					grid: {
						bottom: "20%"
					},
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
							type: 'cross'
					    }
					},
					legend: {
					    data: ['企业数量', '减免金额']
					},
					yAxis: [{
							type: 'value',
							name: "单位：万元",
							position: "lift",
						},
						{
							type: 'value',
							name: "单位：家",
							position: "right",
							// interval: ,
							max:500
						}
					],
					// color:"#005554",
					series: [{
						name:'企业数量',
						data: [2400, 2100, 1500, 2300,1500],
						type: 'line',
						symbolSize: 5,
						symbol: "circle",
						itemStyle: {
							normal:{
								color:"#f35b29",
								lineStyle:{
									color:'#f35b29'
								}
							}
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
						barWidth: 18
					},
					{
						name:'减免金额',
						data: [200, 400, 400, 300,400],
						type: 'line',
						yAxisIndex:1,
						symbolSize: 5,
						symbol: "circle",
						itemStyle: {
							normal:{
								color:"#3df628",
								lineStyle:{
									color:'#3df628'
								}
							}
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
						barWidth: 18
					}
					],
					// dataZoom: [{
					//   type: 'slider',
					//   xAxisIndex: 0,
					//   bottom: 0,
					//   height: 12,
					//   showDetail: false,
					//   startValue: 0,
					//   endValue: 5,
					//   handleSize: '110%',
					//   handleStyle: {
					//     color: "#d3dee5",
					//   },
					//   textStyle: {
					//     color: "#fff"
					//   },
					//   borderColor: "#90979c"
					// }, ],
					title: {
						show: 'true',
						text: '企业减负政策',
						textStyle: {
							color: "#333333",
							fontSize: 18,
						}
					},
				};
				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx {
		width: 100%;
		height: 100%;

		.bar {
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>
