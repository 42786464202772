import { render, staticRenderFns } from "./pobright.vue?vue&type=template&id=6089110f&scoped=true&"
import script from "./pobright.vue?vue&type=script&lang=js&"
export * from "./pobright.vue?vue&type=script&lang=js&"
import style0 from "./pobright.vue?vue&type=style&index=0&id=6089110f&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6089110f",
  null
  
)

export default component.exports