<template>
  <div class="boxx">
    <div ref="bar" class="bar"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    var box = this.$refs.bar;
    this.chart(box);
  },
  methods: {
    chart(box) {
      var myChart = this.$echarts.init(box);
      var option;
      // option = {
      // 	yAxis: {
      // 		type: 'category',
      // 		data: ['龙腾培育', '省科技培育', '高新技术培育', '上市培育', ],
      // 		axisLabel: {
      // 			interval: 0,

      // 		}
      // 	},
      // 	grid: {
      // 		bottom: "20%"
      // 	},
      // 	xAxis: [
      // 		{
      // 			type: 'value',
      // 			name: "",
      // 		},
      // 		{
      // 			type: 'value',
      // 			name: "单位：万元",
      // 		}
      // 	],
      // 	// color:"#005554",
      // 	series: [{
      // 		data: [70, 60, 75, 50],
      // 		type: 'bar',
      // 		itemStyle: {
      // 			color: "#80FFFF"
      // 		},
      // 	 label: {
      // 			show: true,
      // 			position: "top",
      // 			color: "#333"
      // 		},
      // 		barWidth: 18
      // 	}],

      // 	title: {
      // 		show: 'true',
      // 		text: '拟培育企业情况',
      // 		textStyle: {
      // 			color: "#333333",
      // 			fontSize: 18,
      // 		}
      // 	},
      // 	tooltip: {
      // 	    show: true,
      // 	},
      // };
      option = {
        title: {
          text: "核定奖补额",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: [
          {
            type: "category",
            data: [
              "浙江立鹏建设有限公司",
              "浙江正泰电器股份有限公司",
              "温州长江汽车电子有限公司",
              "浙江恒大网络工程有限公司",
              "浙江森马服饰责任有限公司",
              "浙江华峰氨纶股份有限公司",
            ],
          },
          {
            type: "value",
            name: "单位：万元",
          },
        ],
        series: [
          {
            
            type: "bar",
            data: [600, 700, 800, 900, 1000, 1150],
            itemStyle: {
              color: "#80FFFF",
              barBorderRadius: [5],
            },
            barWidth: 18,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.boxx {
  width: 100%;
  height: 100%;

  .bar {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 4px;
  }
}
</style>
