import fetch from "@/api/fetch"
// 产业政策
export function readBcode(){
	return fetch({
		url:'/apg//longGang/BasePortal/readBcode?codeType=POLICY_LEVEL',
		method:'get',
	})
}
export function readBcode2(){
	return fetch({
		url:'/apg//longGang/BasePortal/readBcode?codeType=POLICY_TYPE',
		method:'get',
	})
}
export function listPolicy(page,limit){
	const data={page,limit}
	return fetch({
		url:'/apg/longGang/policy/listPolicy',
		method:'post',
		data
	})
}
export function listPolicy2(page,limit){
	const data={page,limit}
	return fetch({
		url:'/apg/longGang/policy/listPolicy',
		method:'post',
		data
	})
}